/* ============
    Navbar
===============*/
/*---Top Bar--*/
.topbar{
    background-color: #2C2C2C;
}
.custom-language .dropdown-item{
    padding: 5px 10px;
}
.custom-language .dropdown-menu{
    padding:10px;
    border-radius: 0px;
    z-index: 9999;
}
.custom-language .nav-link{
    color: #B9B9B9;
    font-size: 12px;
    padding: 8px 16px;
}
.phone-topbar{
    font-size: 12px;
    padding: 11px 0;
    color: #B9B9B9;
}
.mobile-none{
    display: inline-block;
}
.mobile-block{
    display: none !important;
}
#topnav {
    z-index: 999;
    background-color: #f86f6dcb;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    border: 0;
    transition: all .5s ease;
    min-height: 70px;
    top: 00px;
}

#topnav .navigation-menu {
    float: left;
    list-style: none;
    margin: 0;
    padding: 0;
}
#topnav .navigation-menu.menu-right{
    float: right;
}
#topnav .navigation-menu.menu-right > li > a{
    padding-right: 8px;
}

#topnav .navigation-menu > li {
    float: left;
    display: block;
    position: relative;
    margin: 0;
}

#topnav .navigation-menu > li > a {
    display: block;
    font-size: 15px;
    color: #B9B9B9;
    transition: all .3s ease;
    line-height: 20px;
    padding-left: 16px;
    padding-right: 8px;
    font-weight: 600;
}

#topnav .navigation-menu > li .submenu li a i {
    margin-right: 10px;
    font-size: 14px;
}

#topnav .logo {
    float: left;
    line-height: 70px;
    transition: all .3s ease;
    color: #fff;
    font-weight: 600;
    font-size: 23px;
    margin-right: 20px;
}

#topnav .menu-extras {
    float: right;
}

#topnav .has-submenu.active .submenu li.active > a {
    color: #B9B9B9;
}

#topnav .has-submenu.active a {
    color: #fff;
}
#topnav .has-submenu.active .menu-arrow{
    border-color: #B9B9B9;
}

.has-submenu {
    position: relative;
}

#topnav .navbar-toggle {
    border: 0;
    position: relative;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

#topnav .navbar-toggle .lines {
    width: 25px;
    display: block;
    position: relative;
    margin: 28px auto;
    height: 15px;
}

#topnav .navbar-toggle span {
    height: 2px;
    width: 100%;
    background-color: #ffffff;
    display: block;
    margin-bottom: 5px;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
}

#topnav .navbar-toggle span:last-child {
    margin-bottom: 0;
}

#topnav .navbar-toggle.open span {
    position: absolute;
}

#topnav .navbar-toggle.open span:first-child {
    top: 6px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

#topnav .navbar-toggle.open span:nth-child(2) {
    visibility: hidden;
}

#topnav .navbar-toggle.open span:last-child {
    width: 100%;
    top: 6px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#topnav .navbar-toggle.open span:hover {
    background-color: #19b798;
}

#topnav .navbar-toggle:hover,
#topnav .navbar-toggle:focus,
#topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li > a:focus {
    background-color: transparent;
}

.navigation-menu .has-submenu.active a {
    color: #fff !important;
}

.menu-arrow {
    border: solid #777777;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    position: absolute;
    right: -10px;
    top: 30px;
}

.submenu-arrow {
    border: solid #777777;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    position: absolute;
    right: 20px;
    top: 18px;
}
/*----Topbar Light-----*/
.tagline-light{
    background-color: #f9f9f9;
}
.tagline-light .social li a {
    font-size: 14px;
    color: #000 !important;
}
.topnav-light{
    background-color: #F86F6D !important;
}
.topnav-light .logo {
    color: #fff !important;
}
.topnav-light .navigation-menu .has-submenu.active a {
    color: #fff !important;
}
.topnav-light .navigation-menu > li > a {
    color: #ffffff !important;
}
.topnav-light .navigation-menu > li:hover > a {
    color: rgb(179, 135, 135) !important;
}
/* Searchbar */

.toggle-search {
    font-size: 24px !important;
}

.search-wrap {
    background-color: #ffffff;
    color: #1d1e3a;
    z-index: 9997;
    position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    height: 70px;
    padding: 0 15px;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
    transition: .3s;
}

.search-wrap form {
    display: flex;
    width: 100%;
}

.search-wrap .search-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.search-wrap .search-input {
    flex: 1 1;
    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;
}

.search-wrap .close-search {
    width: 36px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    color: inherit;
    font-size: 24px;
}

.search-wrap .close-search:hover {
    color: #ea553d;
}

.search-wrap.open {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}
.sticky.darkheader {
    top: 0px !important;
}
@media (min-width: 992px) {
    #topnav .navigation-menu > li > a {
        padding-top: 25px;
        padding-bottom: 25px;
        min-height: 62px;
    }
    #topnav .navigation-menu > li.last-elements .submenu {
        left: auto;
        right: 0;
    }
    #topnav .navigation-menu > li.last-elements .submenu > li.has-submenu .submenu {
        left: auto;
        right: 100%;
        margin-left: 0;
        margin-right: 10px;
    }
    #topnav .navigation-menu > li .submenu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        padding: 10px 0;
        list-style: none;
        min-width: 180px;
        visibility: hidden;
        opacity: 0;
        margin-top: 10px;
        transition: all .2s ease;
        background-color: #ffffff;
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
    }
    #topnav .navigation-menu > li .submenu.megamenu {
        white-space: nowrap;
        width: auto;
    }
    #topnav .navigation-menu > li .submenu.megamenu > li {
        overflow: hidden;
        width: 180px;
        display: inline-block;
        vertical-align: top;
    }
    #topnav .navigation-menu > li .submenu > li .submenu {
        left: 100%;
        top: 0;
        margin-left: 10px;
        margin-top: -1px;
    }
    #topnav .navigation-menu > li .submenu li {
        position: relative;
    }
    #topnav .navigation-menu > li .submenu li ul {
        list-style: none;
        padding-left: 0;
        margin: 0;
    }
    #topnav .navigation-menu > li .submenu li a {
        display: block;
        padding: 10px 20px;
        clear: both;
        white-space: nowrap;
        font-size: 14px;
        letter-spacing: 0.03em;
        color: #7a7d84 !important;
        transition: all 0.3s;
    }

    #topnav .navigation-menu > li .submenu li a:hover,#topnav .navigation-menu .has-submenu .submenu > li:hover > a {
        color: #000 !important;
    }
    #topnav .navbar-toggle {
        display: none;
    }
    #topnav #navigation {
        display: block !important;
    }
    #topnav .navigation-menu > li:hover > a {
        color: #fff;
    }
    #topnav .navigation-menu > li:hover > .menu-arrow {
        border-color: #fff;
    }
    #topnav.scroll-active .navigation-menu > li > a {
        padding-top: 25px;
        padding-bottom: 25px;
    }
    #topnav.scroll .navigation-menu > li > a {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    #topnav.scroll {
        top: 0;
    }
    .topnav-light .navigation-menu > li:hover > a {
        color: #000; 
    }
    .topnav-light .navigation-menu > li:hover > .menu-arrow {
        border-color: #000 !important;
    }
}

@media (max-width: 991px) {
    .menu-arrow {
        top: 15px;
        right: 20px;
    }
    .submenu-arrow {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        position: absolute;
        right: 20px;
        top: 12px;
    }
    .logo {
        padding: 0 !important;
        line-height: 0;
    }

    #topnav .container {
        width: auto;
    }
    #topnav .navigation-menu {
        float: none;
        max-height: 400px;
    }
    #topnav .navigation-menu > li {
        float: none;
    }
    #topnav .navigation-menu > li > a {
        color: #B9B9B9;
        padding: 10px 20px;
    }
    #topnav .navigation-menu > li > a:hover,
    #topnav .navigation-menu > li .submenu li a:hover,
    #topnav .navigation-menu > li.has-submenu.open > a,
    #topnav .menu-extras .menu-item .cart > a:hover,
    #topnav .menu-extras .menu-item .search > a:hover {
        color: #000 !important;
    }
    #topnav .navigation-menu > li > a:after {
        position: absolute;
        right: 15px;
    }
    #topnav .navigation-menu > li .submenu {
        display: none;
        list-style: none;
        padding-left: 20px;
        margin: 0;
    }
    #topnav .navigation-menu > li .submenu li a {
        display: block;
        padding: 6px 15px;
        clear: both;
        white-space: nowrap;
        font-size: 14px;
        letter-spacing: 0.03em;
        color: #7a7d84;
        transition: all 0.3s;
    }
    #topnav .navigation-menu .submenu.open .has-submenu.open > a {
        color: #19b798;
    }
    #topnav .navigation-menu > li .submenu.open {
        display: block;
    }
    #topnav .navigation-menu > li .submenu .submenu {
        display: none;
        list-style: none;
    }
    #topnav .navigation-menu > li .submenu .submenu.open {
        display: block;
    }
    #topnav .navigation-menu > li .submenu.megamenu > li > ul {
        list-style: none;
        padding-left: 0;
    }
    #topnav .navigation-menu > li .submenu.megamenu > li > ul > li > span {
        display: block;
        position: relative;
        padding: 10px 15px;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 2px;
        color: #7a7d84;
    }
    #topnav .navbar-toggle span {
        background-color: #7a7d84;
    }
    #topnav .navbar-header {
        float: left;
    }
    #topnav .logo {
        padding: 11px 0;
    }
    #topnav .logo .logo-light {
        display: none;
    }
    #topnav .logo .logo-dark {
        display: block;
    }
    #topnav .has-submenu.active a {
        color: #fff;
    }
    #navigation {
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        display: none;
        height: auto;
        padding-bottom: 0;
        overflow: auto;
        border-top: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;
        background-color: #ffffff;
        z-index: 9999;
    }
    #navigation.open {
        display: block;
        overflow-y: auto;
    }
     .navigation-menu .has-submenu.active a {
        color: #000 !important;
    }
    .topnav-light .navigation-menu > li > a.anchor {
        color: #7a7d84 !important;
    }
    .topnav-light .navigation-menu > li > a.anchor.active,
    .topnav-light .navigation-menu > li > a.anchor.active:hover {
        color: white !important;
    }
    #topnav .navigation-menu.menu-right {
        background-color: #F86F6D;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
    }
}

@media (min-width: 768px) {
    #topnav .navigation-menu > li.has-submenu:hover > .submenu {
        visibility: visible;
        opacity: 1;
        margin-top: 0;
    }
    #topnav .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
        visibility: visible;
        opacity: 1;
        margin-left: 0;
        margin-right: 0;
    }
    .navbar-toggle {
        display: block;
    }

    #topnav .navigation-menu.menu-right {
        margin-right: -10px;
    }
}
@media (max-width: 768px){
    .topbar{
        display: none;
    }
    #topnav{
        top: 0;
    }
    .mobile-none{
        display: none !important;
    }
    .mobile-block{
        display: block !important;
    }
    #topnav .navigation-menu.menu-right{
        float: left
    }
    .navigation-menu .has-submenu.active a {
        color: #000 !important;
    }
}
/* Theme Name: Steix - Responsive Landing Page Template
   Author: Themesbrand
   Version: 1.0.0
   File Description: Main CSS file of the template
*/


/*=========================
 *  1.Helper          *
 *  2.Home            *
 *  3.Features        *
 *  4.Point-Collect   * 
 *  5.Services        *
 *  6.Pricing         *
 *  7.Testimonial     * 
 *  8.Faq             *
 *  9.Contact Us      * 
 *  10.Extra Pages    * 
 *  11.Responsive     *
===========================*/

body {
    color: #505458;
    position: relative;
    font-family: 'Nunito', sans-serif;
}

a {
    text-decoration: none !important;
    outline: none !important;
}


/*=====================
    1.HELPER
======================*/

.section {
    padding-top: 80px;
    padding-bottom: 80px;
}

.text-custom {
    color: #F86F6D;
}

.bg-overlay {
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

.bg-custom {
    background-color: #6362b8;
}

.btn {
    padding: 12px 24px;
}

.btn-rounded {
    border-radius: 30px;
}

.btn-custom {  
    font-weight: 700;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    transition: all 0.3s;
    background-color: #F86F6D95;
    
    box-shadow: none !important;
}

.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active,
.btn-custom.active,
.btn-custom.focus,
.btn-custom:active,
.btn-custom:focus,
.btn-custom:hover,
.open > .dropdown-toggle.btn-custom {
    color: #fff !important;
    transition: all 0.5s;
    background-color: #F86F6D;
}

.section-subtitle {
    font-size: 25px;
    color: #A68D96;
}

.section-title {
    font-size: 30px;
    color: #F86F6D;
    text-transform: capitalize;
}

.title-icon {
    color: #6362b8;
    font-size: 36px;
}

.vertical-content {
    display: -moz-flex;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: row;
}


/*====================================
        2.HOME
======================================*/

.home-bg-color {
    background-color: #6362bb;
    padding-top: 80px;
    position: relative;
}

.home-subtitle {
    color: #C0C0E2;
    font-size: 18px;
    max-width: 750px;
}

.home-title {
    font-weight: 400;
}

.watch-overview {
    font-size: 18px;
}

.watch-overview i {
    border: 2px solid #fff;
    height: 24px;
    width: 24px;
    line-height: 20px;
    font-size: 18px;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    top: 2px;
}

.home-dashboard {
    position: relative;
    top: 80px;
}


/*--Home Bg-img---*/
/* 
.home-bg-img {
    background-position: center center;
    background-size: cover;
    background-image: url('../images/img-1.jpg');
    position: relative;
    padding-top: 80px;
} */


/*---Home Bg-Video---*/

.home-bg-video {
    background-color: #000;
    padding-top: 80px;
}


/*--Home Full Screen---*/

/* .home-fullscreen {
    padding-top: 130px;
    padding-bottom: 200px;
    background-position: center center;
    background-size: cover;
    background-image: url('../images/img-1.jpg');
    position: relative;
} */

.home-center {
    display: table;
    width: 100%;
    height: 100%;
}

.home-desc-center {
    display: table-cell;
    vertical-align: middle;
}


/*--Home Slider---*/

.home-slider .carousel-item {
    padding-top: 50%;
    padding-bottom: 250px;
    background-size: cover;
    background-position: center center;
}

.carousel-control-next,
.carousel-control-prev {
    width: 3%;
    font-size: 30px;
}


/*--HOME ANIMATION--*/

.slidero {
    position: relative;
    z-index: 1;
}


/*====================
    3.Features
=====================*/

.features-icon {
   padding-right: 2%;

}

.features-desc h5 {
    font-size: 20px;
    color: #000;
}

.features-more i {
    position: relative;
    top: 1px;
}

.features-more {
    color: #6362b8 !important;
}

.features-agenda{
    flex-direction: row;
}

.formacion{
    justify-content: space-around;
}




/*========================
    4.Point-Collect
==========================*/

.counter-icon {
    font-size: 42px;
    margin-bottom: 20px;
}

.counter-name {
    font-size: 16px;
}


/*===========================
    5.Services
=============================*/

.service-cita{
    text-align: right;
    padding-right: 10%;
    color: #A68D96;
}

.service-icon i {
    display: inline-block;
    font-size: 36px;
    width: 90px;
    height: 90px;
    line-height: 84px;
    color: #2f2f2f;
    border: 5px solid #FFFFFF;
    border-radius: 50%;
    box-shadow: 0 0 3px #bbb;
    transition: all 0.3s;
    background-color: #fafafa;
}

.service-box .service-icon:hover .icon-check {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    transition: all 0.5s;
    color: #fff;
    /* background: #6362b8;
    box-shadow: 0 0 3px #6362b8; */
}

.icon-calendar {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    transition: all 0.5s;
    color: #fff;
    /* background: #6362b8;
    box-shadow: 0 0 3px #6362b8; */
}


.service-desc h5 {
    font-size: 20px;
    color: #000;
}

.service-box {
    border-radius: 5px;
    transition: all 0.5s;
    justify-content: flex-start;
    /* flex-direction: row !important; */
}

.service-box:hover {
    border-color: transparent;
}

.service-btn{
    flex-direction: row-reverse;
}

.service-check{
    padding-top: 1%;
    padding-right: 3%;
}

.service-exp{
    padding-top: 0.5px !important;
    padding-left: 7%;
}


/*===========================
    PROCEDURES
=============================*/
.padding-lateral{
    padding-left: 15%;
}



/*===========================
    GALERIA
=============================*/
bootstrap-sm: 576px;
bootstrap-md: 768px;
bootstrap-lg: 992px;
bootstrap-xl: 1200px;
 

#gallery img{
   
    height: 75vw;
    object-fit: cover;
    
    @media (min-width: $bootstrap-sm) {
      height: 35vw;
    }
    
    @media (min-width: $bootstrap-lg) {
      height: 18vw;
    }
  
}
 

.carousel-item img{
  
    height: 60vw;
    object-fit: cover;
    
    @media (min-width: $bootstrap-sm) {
      height: 350px;
    }
  
}

/*===========================
    6.Pricing
=============================*/

.plan-price h2 {
    font-size: 50px;
    font-weight: 700;
}

.plan-name h6 {
    font-size: 22px;
}

.plan-lable p {
    font-weight: 700;
}

/*===========================
    MAPA
=============================*/

.map-responsive{
    overflow: hidden;
    /* padding-bottom: 100%; */
    position: relative;
    height: 450px;
    padding-top: 10%;
    box-shadow: 4px 5px 9px 0px rgb(0 0 0 / 30%);
}

.map-responsive iframe{
    left: 0;
    top: 0;
    height: 450px;
    width: 100%;
    position: absolute;
    border: none;
    border-radius: 10px;
}

/*===========================
    7.Testimonial
=============================*/

.testimonial-desc:after {
    content: "";
    bottom: -15px;
    left: 47%;
    position: absolute;
    width: 0;
    height: 0;
    border-top: 15px solid #f8f9fa;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}

.testimonial-desc {
    border-radius: 10px;
    position: relative;
}

.testimonial-desc p {
    font-size: 15px;
    line-height: 26px;
}

.testi-img img {
    border: 4px solid #fff;
    border-radius: 50%;
    box-shadow: 0 0 3px #bbb;
    transition: all 0.5s;
    height: 80px;
    width: 80px;
}

.quote-icon i {
    font-size: 50px;
}


/*===========================
    8.Faq
=============================*/

.faq-title {
    font-size: 16px;
    font-weight: 600;
}

.faq-title i {
    position: relative;
    top: 1px;
    font-size: 16px;
}

.user-title {
    font-size: 12px;
    font-weight: 600;
    color: #edc4c6;
}

.faq-sub-title {
    font-size: 14px;
    line-height: 22px;
}


/*===========================
    9.Contact-us
============================*/

.single-contact {
    text-align: center;
    padding: 15px 0;
}

.single-contact h4 {
    font-size: 18px;
    line-height: 24px;
    margin: 25px 0 5px;
}

.single-contact p {
    margin-bottom: 0;
}

.single-contact i {
    display: inline-block;
    font-size: 26px;
    color: #F86F6D;
    
}

.custom-form .form-control {
    height: 50px;
}

textarea.form-control {
    height: auto !important;
}

.submit-terms {
    font-size: 13px;
}

.copyright-desc {
    font-size: 20px;
    margin-bottom: 5px
}

.copyright-sub {
    font-size: 14px;
    margin-bottom: 5px
}

.social li a {
    font-size: 14px;
}

.error {
    margin: 8px 0px;
    display: none;
    color: red;
}

#ajaxsuccess {
    font-size: 16px;
    width: 100%;
    display: none;
    clear: both;
    margin: 8px 0px;
}

.error_message {
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    border: 2px solid #f36270;
    color: #f36270;
    border-radius: 5px;
    font-size: 14px;
}

.contact-loader {
    display: none;
}

#success_page {
    text-align: center;
    margin-bottom: 50px;
}

#success_page h3 {
    color: #0dba26;
    font-size: 22px;
}


/*=====================================
        10.EXTRA PAGES
=======================================*/


/*--LOGIN PAGE/SIGNUP/FORGOTPASS--*/

.login-table {
    display: table;
    width: 100%;
    height: 100%;
}

.login-table-cell {
    display: table-cell;
    vertical-align: middle;
}

.login-form .form-control {
    height: 50px;
    border: none;
    border-bottom: 2px solid #d8d8d8;
    border-radius: 0px;
    outline: none !important;
    box-shadow: none !important;
}

.login-form .form-control:focus {
    border-color: #6362b8;
}

.forgot-pass-txt {
    line-height: 22px;
}

.home-icon {
    font-size: 50px;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #6362b8;
}

.alert-custom{
    color: #6362b8;
    background-color: rgba(99, 98, 184, 0.2);
    border-color: rgba(99, 98, 184, .7);
}

/*=====================================
        12.Mi CONSULTORIO
=======================================*/

.bg-cons{
    background-color: #fcd3d3ae;
}

.title-cons{
    color: #A68D96;
}
 .title-op{
    text-align: left;
 }

.color-items{
    color: #000000;
    justify-content: center;
}
/*=====================================
        12.FOOTER
=======================================*/
.container-footer{
 width: 99%;
}

.footer{
    background-color: #f86f6dcb;
    padding-left: 15%;
    padding-right: 15%;
}

.footer-txt{
    padding-top: 1%;
    padding-left: 2%;
}

.icons-foot{
    justify-content: center;
}


/*=====================================
        12.RESPONSIVE
=======================================*/

@media (max-width: 768px) {
    .point-collect h1 {
        font-size: 50px;
    }
    .point-collect h3 {
        font-size: 20px;
    }
    .footer-alt .float-left,
    .footer-alt .float-right {
        float: none !important;
        text-align: center;
    }
    .home-title {
        font-size: 30px;
    }
    .testimonial-box {
        margin-top: 20px;
    }
    .home-fullscreen,
    .home-slider .carousel-item {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .section-subtitle {
        font-size: 40px;
    }
    .section-title {
        font-size: 24px;
    }
    .carousel-control-next,
    .carousel-control-prev {
        width: 10%;
    }
    .price-box {
        margin-top: 20px !important
    }
}

@media (max-width: 767px) {
    .vertical-content {
        display: inherit;
    }
}


#topnav .navigation-menu {
    float: none;
    max-height: 400px;
}

.menu-extras .navigation-menu  .has-submenu
{
    float: left;
}
.justify-content-center1
{
    margin-top: -80%!important;
}
#my_icon
{
    display: -webkit-box;
}
.bg-custom1
{
    background-repeat:no-repeat;
    background: #6362b8;
    height: 100vh;
    overflow: scroll;
}

#background-video{

    height: 100%;
    width: 100%;
    float: left;
    top: 0;
    padding: none;
    position: fixed; /* optional depending on what you want to do in your app */
    }
    #containervideo
    {
        margin-top: -1050px!important;
    }
    .styles_modal__gNwvD
    {
        height: 29% !important;
        width: 30% !important;
        background-color: #2f2f2f!important;
    }

    #err
    {
        color: red;
    }
#home{
  min-height: calc(100vh - 70px );
  background: #fa9997;
  background: linear-gradient(180deg, #fa9997 0%, #ffffff 100%);
}
.service-desc p{
  text-align: center;
  font-size: 0.8rem;
}
.topnav-light {
  background-color: #F86F6DB5 !important;
}

.topest{
  position: fixed;
  z-index: 999;
}
.navigation-menu.menu-right{
  display: flex;
  justify-content: space-between;
}
.navigation-menu .list-inline-item{
  padding: 0;
}
.galery-item {
  width: 45%;
  /* height: 225px; */
  border-radius: 10px;
  overflow: hidden;
  transition: -webkit-transform ease-in-out 0.2s;
  transition: transform ease-in-out 0.2s;
  transition: transform ease-in-out 0.2s, -webkit-transform ease-in-out 0.2s;
}
.galery-item:active,
.galery-item:hover {
  box-shadow: 4px 5px 9px 0px rgba(0,0,0,0.5);
-webkit-box-shadow: 4px 5px 9px 0px rgba(0,0,0,0.5);
-moz-box-shadow: 4px 5px 9px 0px rgba(0,0,0,0.5);
-webkit-transform: translateY(-12px);
        transform: translateY(-12px);
}
.galery-item img {
  width: 100%;
  height: 225px;
  object-fit: cover;
  object-position: center;
}
 /* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
  
 }

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .galery-item {
    width: unset;
  }
  .galery-item img {
    width: 200px;
    height: 225px;
  }

 }

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

 }

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 

 }
/*===========================
    INFORMACION
=============================*/
.title-card{
  font-size: 12px;
  color: #6c757d;
  text-transform: capitalize;
}

.container-card{
  max-width: 100%;
  justify-content: space-between;
  flex-direction: row;
  padding-left: 15%;
  padding-right: 15%;
}

.padding-top{
  padding-top: 10%;
}
.info-grid{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-row-gap: 2rem;
  row-gap: 2rem;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
}
.card-container{
  width: 100%;
  height: 255px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 4px 5px 9px 0px rgba(0,0,0,0.3);
-webkit-box-shadow: 4px 5px 9px 0px rgba(0,0,0,0.3);
-moz-box-shadow: 4px 5px 9px 0px rgba(0,0,0,0.3);
}
.card-info{
/* z-index: 1; */
/* background-color: #3f7bfad5; */
transition: all ease-out 0.5s;
opacity: 0;
}
.card-info .title{
  font-size: 12px;
  color: white;
}
.card-info > * {
  color: white;
}
.card-info a {
  font-weight: bold;;
}
.card-overlay{
/* z-index: 2; */
background-color: white;
transition: all ease-out 0.5s;
}

.card-container:hover .card-overlay{
  /* z-index: 1; */
  -webkit-filter: blur(5px);
          filter: blur(5px);
  
}
.card-container:hover .card-info{
  opacity: 1;
  /* z-index: 2; */
}

.card-info {
  background-color: #6c757d;
  transition: all ease-out 0.5s;
  opacity: 0;
}
.anchor {
  cursor: pointer;
}
.anchor.active {
  background-color: #F86F6D !important;
  /* box-shadow: 0px 0px 15px 10px rgba(255,255,255,0.75);
-webkit-box-shadow: 0px 0px 15px 10px rgba(255,255,255,0.75);
-moz-box-shadow: 0px 0px 15px 10px rgba(255,255,255,0.75); */
}

@media (min-width: 768px) {
  .card-container {
    width: 230px;
    height: 285px;
  }
  .card-info .title {
    font-size: 1rem;
  }
 }
