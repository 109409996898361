/* Theme Name: Steix - Responsive Landing Page Template
   Author: Themesbrand
   Version: 1.0.0
   File Description: Main CSS file of the template
*/


/*=========================
 *  1.Helper          *
 *  2.Home            *
 *  3.Features        *
 *  4.Point-Collect   * 
 *  5.Services        *
 *  6.Pricing         *
 *  7.Testimonial     * 
 *  8.Faq             *
 *  9.Contact Us      * 
 *  10.Extra Pages    * 
 *  11.Responsive     *
===========================*/

body {
    color: #505458;
    position: relative;
    font-family: 'Nunito', sans-serif;
}

a {
    text-decoration: none !important;
    outline: none !important;
}


/*=====================
    1.HELPER
======================*/

.section {
    padding-top: 80px;
    padding-bottom: 80px;
}

.text-custom {
    color: #F86F6D;
}

.bg-overlay {
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

.bg-custom {
    background-color: #6362b8;
}

.btn {
    padding: 12px 24px;
}

.btn-rounded {
    border-radius: 30px;
}

.btn-custom {  
    font-weight: 700;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    transition: all 0.3s;
    background-color: #F86F6D95;
    
    box-shadow: none !important;
}

.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active,
.btn-custom.active,
.btn-custom.focus,
.btn-custom:active,
.btn-custom:focus,
.btn-custom:hover,
.open > .dropdown-toggle.btn-custom {
    color: #fff !important;
    transition: all 0.5s;
    background-color: #F86F6D;
}

.section-subtitle {
    font-size: 25px;
    color: #A68D96;
}

.section-title {
    font-size: 30px;
    color: #F86F6D;
    text-transform: capitalize;
}

.title-icon {
    color: #6362b8;
    font-size: 36px;
}

.vertical-content {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: row;
    -webkit-flex-direction: row;
}


/*====================================
        2.HOME
======================================*/

.home-bg-color {
    background-color: #6362bb;
    padding-top: 80px;
    position: relative;
}

.home-subtitle {
    color: #C0C0E2;
    font-size: 18px;
    max-width: 750px;
}

.home-title {
    font-weight: 400;
}

.watch-overview {
    font-size: 18px;
}

.watch-overview i {
    border: 2px solid #fff;
    height: 24px;
    width: 24px;
    line-height: 20px;
    font-size: 18px;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    top: 2px;
}

.home-dashboard {
    position: relative;
    top: 80px;
}


/*--Home Bg-img---*/
/* 
.home-bg-img {
    background-position: center center;
    background-size: cover;
    background-image: url('../images/img-1.jpg');
    position: relative;
    padding-top: 80px;
} */


/*---Home Bg-Video---*/

.home-bg-video {
    background-color: #000;
    padding-top: 80px;
}


/*--Home Full Screen---*/

/* .home-fullscreen {
    padding-top: 130px;
    padding-bottom: 200px;
    background-position: center center;
    background-size: cover;
    background-image: url('../images/img-1.jpg');
    position: relative;
} */

.home-center {
    display: table;
    width: 100%;
    height: 100%;
}

.home-desc-center {
    display: table-cell;
    vertical-align: middle;
}


/*--Home Slider---*/

.home-slider .carousel-item {
    padding-top: 50%;
    padding-bottom: 250px;
    background-size: cover;
    background-position: center center;
}

.carousel-control-next,
.carousel-control-prev {
    width: 3%;
    font-size: 30px;
}


/*--HOME ANIMATION--*/

.slidero {
    position: relative;
    z-index: 1;
}


/*====================
    3.Features
=====================*/

.features-icon {
   padding-right: 2%;

}

.features-desc h5 {
    font-size: 20px;
    color: #000;
}

.features-more i {
    position: relative;
    top: 1px;
}

.features-more {
    color: #6362b8 !important;
}

.features-agenda{
    flex-direction: row;
}

.formacion{
    justify-content: space-around;
}




/*========================
    4.Point-Collect
==========================*/

.counter-icon {
    font-size: 42px;
    margin-bottom: 20px;
}

.counter-name {
    font-size: 16px;
}


/*===========================
    5.Services
=============================*/

.service-cita{
    text-align: right;
    padding-right: 10%;
    color: #A68D96;
}

.service-icon i {
    display: inline-block;
    font-size: 36px;
    width: 90px;
    height: 90px;
    line-height: 84px;
    color: #2f2f2f;
    border: 5px solid #FFFFFF;
    border-radius: 50%;
    box-shadow: 0 0 3px #bbb;
    transition: all 0.3s;
    background-color: #fafafa;
}

.service-box .service-icon:hover .icon-check {
    transform: scale(1.2);
    transition: all 0.5s;
    color: #fff;
    /* background: #6362b8;
    box-shadow: 0 0 3px #6362b8; */
}

.icon-calendar {
    transform: scale(1.2);
    transition: all 0.5s;
    color: #fff;
    /* background: #6362b8;
    box-shadow: 0 0 3px #6362b8; */
}


.service-desc h5 {
    font-size: 20px;
    color: #000;
}

.service-box {
    border-radius: 5px;
    transition: all 0.5s;
    justify-content: flex-start;
    /* flex-direction: row !important; */
}

.service-box:hover {
    border-color: transparent;
}

.service-btn{
    flex-direction: row-reverse;
}

.service-check{
    padding-top: 1%;
    padding-right: 3%;
}

.service-exp{
    padding-top: 0.5px !important;
    padding-left: 7%;
}


/*===========================
    PROCEDURES
=============================*/
.padding-lateral{
    padding-left: 15%;
}



/*===========================
    GALERIA
=============================*/
bootstrap-sm: 576px;
bootstrap-md: 768px;
bootstrap-lg: 992px;
bootstrap-xl: 1200px;
 

#gallery img{
   
    height: 75vw;
    object-fit: cover;
    
    @media (min-width: $bootstrap-sm) {
      height: 35vw;
    }
    
    @media (min-width: $bootstrap-lg) {
      height: 18vw;
    }
  
}
 

.carousel-item img{
  
    height: 60vw;
    object-fit: cover;
    
    @media (min-width: $bootstrap-sm) {
      height: 350px;
    }
  
}

/*===========================
    6.Pricing
=============================*/

.plan-price h2 {
    font-size: 50px;
    font-weight: 700;
}

.plan-name h6 {
    font-size: 22px;
}

.plan-lable p {
    font-weight: 700;
}

/*===========================
    MAPA
=============================*/

.map-responsive{
    overflow: hidden;
    /* padding-bottom: 100%; */
    position: relative;
    height: 450px;
    padding-top: 10%;
    box-shadow: 4px 5px 9px 0px rgb(0 0 0 / 30%);
}

.map-responsive iframe{
    left: 0;
    top: 0;
    height: 450px;
    width: 100%;
    position: absolute;
    border: none;
    border-radius: 10px;
}

/*===========================
    7.Testimonial
=============================*/

.testimonial-desc:after {
    content: "";
    bottom: -15px;
    left: 47%;
    position: absolute;
    width: 0;
    height: 0;
    border-top: 15px solid #f8f9fa;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}

.testimonial-desc {
    border-radius: 10px;
    position: relative;
}

.testimonial-desc p {
    font-size: 15px;
    line-height: 26px;
}

.testi-img img {
    border: 4px solid #fff;
    border-radius: 50%;
    box-shadow: 0 0 3px #bbb;
    transition: all 0.5s;
    height: 80px;
    width: 80px;
}

.quote-icon i {
    font-size: 50px;
}


/*===========================
    8.Faq
=============================*/

.faq-title {
    font-size: 16px;
    font-weight: 600;
}

.faq-title i {
    position: relative;
    top: 1px;
    font-size: 16px;
}

.user-title {
    font-size: 12px;
    font-weight: 600;
    color: #edc4c6;
}

.faq-sub-title {
    font-size: 14px;
    line-height: 22px;
}


/*===========================
    9.Contact-us
============================*/

.single-contact {
    text-align: center;
    padding: 15px 0;
}

.single-contact h4 {
    font-size: 18px;
    line-height: 24px;
    margin: 25px 0 5px;
}

.single-contact p {
    margin-bottom: 0;
}

.single-contact i {
    display: inline-block;
    font-size: 26px;
    color: #F86F6D;
    
}

.custom-form .form-control {
    height: 50px;
}

textarea.form-control {
    height: auto !important;
}

.submit-terms {
    font-size: 13px;
}

.copyright-desc {
    font-size: 20px;
    margin-bottom: 5px
}

.copyright-sub {
    font-size: 14px;
    margin-bottom: 5px
}

.social li a {
    font-size: 14px;
}

.error {
    margin: 8px 0px;
    display: none;
    color: red;
}

#ajaxsuccess {
    font-size: 16px;
    width: 100%;
    display: none;
    clear: both;
    margin: 8px 0px;
}

.error_message {
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    border: 2px solid #f36270;
    color: #f36270;
    border-radius: 5px;
    font-size: 14px;
}

.contact-loader {
    display: none;
}

#success_page {
    text-align: center;
    margin-bottom: 50px;
}

#success_page h3 {
    color: #0dba26;
    font-size: 22px;
}


/*=====================================
        10.EXTRA PAGES
=======================================*/


/*--LOGIN PAGE/SIGNUP/FORGOTPASS--*/

.login-table {
    display: table;
    width: 100%;
    height: 100%;
}

.login-table-cell {
    display: table-cell;
    vertical-align: middle;
}

.login-form .form-control {
    height: 50px;
    border: none;
    border-bottom: 2px solid #d8d8d8;
    border-radius: 0px;
    outline: none !important;
    box-shadow: none !important;
}

.login-form .form-control:focus {
    border-color: #6362b8;
}

.forgot-pass-txt {
    line-height: 22px;
}

.home-icon {
    font-size: 50px;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #6362b8;
}

.alert-custom{
    color: #6362b8;
    background-color: rgba(99, 98, 184, 0.2);
    border-color: rgba(99, 98, 184, .7);
}

/*=====================================
        12.Mi CONSULTORIO
=======================================*/

.bg-cons{
    background-color: #fcd3d3ae;
}

.title-cons{
    color: #A68D96;
}
 .title-op{
    text-align: left;
 }

.color-items{
    color: #000000;
    justify-content: center;
}
/*=====================================
        12.FOOTER
=======================================*/
.container-footer{
 width: 99%;
}

.footer{
    background-color: #f86f6dcb;
    padding-left: 15%;
    padding-right: 15%;
}

.footer-txt{
    padding-top: 1%;
    padding-left: 2%;
}

.icons-foot{
    justify-content: center;
}


/*=====================================
        12.RESPONSIVE
=======================================*/

@media (max-width: 768px) {
    .point-collect h1 {
        font-size: 50px;
    }
    .point-collect h3 {
        font-size: 20px;
    }
    .footer-alt .float-left,
    .footer-alt .float-right {
        float: none !important;
        text-align: center;
    }
    .home-title {
        font-size: 30px;
    }
    .testimonial-box {
        margin-top: 20px;
    }
    .home-fullscreen,
    .home-slider .carousel-item {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .section-subtitle {
        font-size: 40px;
    }
    .section-title {
        font-size: 24px;
    }
    .carousel-control-next,
    .carousel-control-prev {
        width: 10%;
    }
    .price-box {
        margin-top: 20px !important
    }
}

@media (max-width: 767px) {
    .vertical-content {
        display: inherit;
    }
}


#topnav .navigation-menu {
    float: none;
    max-height: 400px;
}

.menu-extras .navigation-menu  .has-submenu
{
    float: left;
}
.justify-content-center1
{
    margin-top: -80%!important;
}
#my_icon
{
    display: -webkit-box;
}
.bg-custom1
{
    background-repeat:no-repeat;
    background: #6362b8;
    height: 100vh;
    overflow: scroll;
}

#background-video{

    height: 100%;
    width: 100%;
    float: left;
    top: 0;
    padding: none;
    position: fixed; /* optional depending on what you want to do in your app */
    }
    #containervideo
    {
        margin-top: -1050px!important;
    }
    .styles_modal__gNwvD
    {
        height: 29% !important;
        width: 30% !important;
        background-color: #2f2f2f!important;
    }

    #err
    {
        color: red;
    }