#home{
  min-height: calc(100vh - 70px );
  background: #fa9997;
  background: -webkit-linear-gradient(180deg, #fa9997 0%, #ffffff 100%);
  background: linear-gradient(180deg, #fa9997 0%, #ffffff 100%);
}
.service-desc p{
  text-align: center;
  font-size: 0.8rem;
}
.topnav-light {
  background-color: #F86F6DB5 !important;
}

.topest{
  position: fixed;
  z-index: 999;
}
.navigation-menu.menu-right{
  display: flex;
  justify-content: space-between;
}
.navigation-menu .list-inline-item{
  padding: 0;
}
.galery-item {
  width: 45%;
  /* height: 225px; */
  border-radius: 10px;
  overflow: hidden;
  transition: transform ease-in-out 0.2s;
}
.galery-item:active,
.galery-item:hover {
  box-shadow: 4px 5px 9px 0px rgba(0,0,0,0.5);
-webkit-box-shadow: 4px 5px 9px 0px rgba(0,0,0,0.5);
-moz-box-shadow: 4px 5px 9px 0px rgba(0,0,0,0.5);
transform: translateY(-12px);
}
.galery-item img {
  width: 100%;
  height: 225px;
  object-fit: cover;
  object-position: center;
}
 /* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
  
 }

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .galery-item {
    width: unset;
  }
  .galery-item img {
    width: 200px;
    height: 225px;
  }

 }

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

 }

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 

 }
/*===========================
    INFORMACION
=============================*/
.title-card{
  font-size: 12px;
  color: #6c757d;
  text-transform: capitalize;
}

.container-card{
  max-width: 100%;
  justify-content: space-between;
  flex-direction: row;
  padding-left: 15%;
  padding-right: 15%;
}

.padding-top{
  padding-top: 10%;
}
.info-grid{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 2rem;
  column-gap: 2rem;
}
.card-container{
  width: 100%;
  height: 255px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 4px 5px 9px 0px rgba(0,0,0,0.3);
-webkit-box-shadow: 4px 5px 9px 0px rgba(0,0,0,0.3);
-moz-box-shadow: 4px 5px 9px 0px rgba(0,0,0,0.3);
}
.card-info{
/* z-index: 1; */
/* background-color: #3f7bfad5; */
transition: all ease-out 0.5s;
opacity: 0;
}
.card-info .title{
  font-size: 12px;
  color: white;
}
.card-info > * {
  color: white;
}
.card-info a {
  font-weight: bold;;
}
.card-overlay{
/* z-index: 2; */
background-color: white;
transition: all ease-out 0.5s;
}

.card-container:hover .card-overlay{
  /* z-index: 1; */
  filter: blur(5px);
  
}
.card-container:hover .card-info{
  opacity: 1;
  /* z-index: 2; */
}

.card-info {
  background-color: #6c757d;
  transition: all ease-out 0.5s;
  opacity: 0;
}
.anchor {
  cursor: pointer;
}
.anchor.active {
  background-color: #F86F6D !important;
  /* box-shadow: 0px 0px 15px 10px rgba(255,255,255,0.75);
-webkit-box-shadow: 0px 0px 15px 10px rgba(255,255,255,0.75);
-moz-box-shadow: 0px 0px 15px 10px rgba(255,255,255,0.75); */
}

@media (min-width: 768px) {
  .card-container {
    width: 230px;
    height: 285px;
  }
  .card-info .title {
    font-size: 1rem;
  }
 }